<template>
    <div id="changepassword">
         <div class="logo">
                <img src="../assets/image/a.png" alt="">
                <div class="title">修改密码</div>
         </div>
          <div class="loginTable">
        <van-cell-group>
            <van-field
              v-model="oldpassword"
               type="password"
              clearable
              required
              label="初始密码"
              placeholder="请输入原始密码"
            />
            <van-field
              v-model="newpassword"
              type="password"
              required
              placeholder="请输入新密码"
               label="新密码"
               clearable
            />
            <van-field
              v-model="curpassword"
              type="password"
               label="确认密码"
               required
              placeholder="请确认新密码"
               clearable
            />
          </van-cell-group>
      </div>
      <van-button type="info" style="width:80%;margin-top:120px" @click="updatepassword" >修改密码</van-button>  
    </div>
</template>
<script>
import { updatePassword} from "@/api/goods.js";
export default {
     data(){
            return{
            oldpassword:"",
            newpassword:"",
            curpassword:"",
            }
    },
    methods:{
        updatepassword(){
            if(!this.oldpassword){
            this.$notify({
                message: '请输入原始密码',
                duration: 2000,
                background: '#f44'
                });
           }else if(!this.newpassword){
                this.$notify({
                message: '请输入原始密码',
                duration: 2000,
                background: '#f44'
                });
           }else if(!this.curpassword){
                this.$notify({
                message: '请确认新密码',
                duration: 2000,
                background: '#f44'
                });
           }else if(this.newpassword!=this.curpassword){
                this.$notify({
                message: '两次密码不一致，请重新输入！',
                duration: 2000,
                background: '#f44'
                });
           }else{
               updatePassword({
                //  stunum:localStorage.getItem('stunum'),
                 password:this.oldpassword,
                 nwoPasswordOne:this.newpassword,
                 nwoPasswordTwo:this.curpassword
               }).then(res=>{
                  this.$toast({
                    message:res.data.code==200?"修改成功,请重新登录！":res.data.message,
                    type:res.data.code==200?"success":'fail', 
                    close:()=>{
                         this.$router.push('/Login')
                    }
                  })
               })
           }
    }
  }
}
</script>
<style lang="scss" scoped>
#changepassword{
  width: 100%;
  height: 100%;
    .logo{
     margin-top:50px;
     width: 100%;
     height: 200px;
     .title{
       text-align: center;
       width: 100%;
       font-size: 20px;
     }
  }
}
</style>